import React from 'react';
import TermsHeader from 'components/Terms/TermsHeader';
import TermsContent from 'components/Terms/TermsContent';
import LightTheme from 'layouts/Light';

const TermsOfUse = () => {
  return (
    <LightTheme>
      <TermsHeader />
      <TermsContent />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>UniService.Vn - Terms Of Use</title>
    </>
  );
};

export default TermsOfUse;
